import { AcquaintApiClient } from "@/services/AcquaintApiClient";
import { Mutex } from "async-mutex";
import { defineStore } from "pinia";
import { inject, reactive } from "vue";
import { SystemConfiguration } from "acquaint-api/constants/SystemConfiguration";
import { IDValuePair } from "@/types/generic/IDValuePair";

export const useSystemConfigurationStore = defineStore('systemConfiguration', () => {
    const apiClient = inject('AcquaintApiClient') as AcquaintApiClient;
    const mutex = new Mutex();

    const systemConfigurationCache = reactive<IDValuePair[]>([]);

    /** Gets a System Configuration value for the currently logged in branch */
    const getSystemConfigurationValue = async (id :SystemConfiguration) => {
        const systemConfigValue = await mutex.runExclusive(async () => {
            // To avoid unnecessary api calls, check cache first
            let cachedValue = systemConfigurationCache.find(item => item.id === id);

            /* If cached item found then return it. 
             * Note: need to explicitly check for undefined as empty strings are valid values */
            if (cachedValue != undefined) {
                return cachedValue.val;
            }

            cachedValue = await getSystemConfigurationValueFromApi(id);
            return cachedValue.val;
        });

        return systemConfigValue;
    }

    const getSystemConfigurationValueFromApi = async (id :SystemConfiguration) => {
        return await apiClient.httpGet<IDValuePair>(`app/system/configuration/branch/${id}`)
    }

    const getSystemConfigurationValueWithSiteId = async (id :SystemConfiguration, siteId: number) => {
        const systemConfigValue = await mutex.runExclusive(async () => {
            const cachedValue = await getSystemConfigurationValueFromApiWithSiteId(id, siteId);
            return cachedValue.val;
        });

        return systemConfigValue;
    }

    const getSystemConfigurationValueFromApiWithSiteId = async (id :SystemConfiguration, siteId: number) => {
        return await apiClient.httpGet<IDValuePair>(`app/system/configuration/branch/${siteId}/${id}`)
    }

    return { getSystemConfigurationValue, getSystemConfigurationValueWithSiteId }
});